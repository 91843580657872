import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel, Carousel, NamedLink } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';

import css from './ListingPage.module.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    authorDisplayName,
    author,
  } = props;

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const hasImages = listing.images && listing.images.length > 0;
  const listingImages = hasImages ? listing.images : null;

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = isOwnListing ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  const authorLink = author?.id?.uuid ? (
    <NamedLink
      className={css.imageLabelAuthor}
      name="ProfilePage"
      params={{ id: author?.id?.uuid }}
    >
      {authorDisplayName}
    </NamedLink>
  ) : null;

  const variants = ['listing-card', 'listing-card2x'];

  return (
    <div className={css.sectionImages}>
      <Carousel className={css.carousel} showCarousel={hasImages}>
        {hasImages &&
          listingImages.map((image, index) => {
            const imageId = image?.id?.uuid;
            return (
              <div className={css.threeToTwoWrapper} key={imageId}>
                <div
                  className={css.aspectWrapper}
                  onClick={e => {
                    handleViewPhotosClick(e);
                    setSelectedImageIndex(index);
                  }}
                >
                  {actionBar}
                  <ResponsiveImage
                    rootClassName={css.rootForImage}
                    alt={title}
                    image={image}
                    variants={variants}
                  />
                  {viewPhotosButton}
                </div>
              </div>
            );
          })}
      </Carousel>
      <div className={css.imageLabelContainer}>
        <div className={css.imageLabel}>
          <FormattedMessage
            id="ListingPage.imageLabel"
            values={{
              author: authorLink,
            }}
          />
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} selectedImageFromProps={selectedImageIndex} />
      </Modal>
    </div>
  );
};

export default SectionImages;
