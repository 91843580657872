import React, { useState, useEffect } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { normalizeYelpReviews } from '../../util/data';
import { Reviews } from '../../components';
import axios from 'axios';

import css from './ListingPage.module.css';

const EMPTY_ARRAY = new Array();
const BLACKLISTED_REVIEW_IDS = ['xQgyYsaRPIIx8rJ_fujzyw'];

const SectionReviews = props => {
  const {
    reviews,
    oldReviews,
    hasYelp,
    listing,
    fetchReviewsError,
    customerReviews,
    onManageDisableScrolling,
  } = props;

  const [yelpReviews, setYelpReviews] = useState(EMPTY_ARRAY);
  const [fetchYelpReviewsInProgress, setFetchYelpReviewsInProgress] = useState(false);
  const [yelpReviewsError, setYelpReviewsError] = useState(null);

  useEffect(() => {
    // Make the API call only if listing has
    // yelp credentials saved
    if (hasYelp) {
      (async function() {
        setFetchYelpReviewsInProgress(true);
        try {
          const response = await axios.get(`/get-yelp-reviews?id=${listing?.id?.uuid}`);
          const normalizedReviews = normalizeYelpReviews(response.data.reviews);

          setFetchYelpReviewsInProgress(false);
          setYelpReviews(normalizedReviews);
        } catch (e) {
          console.log(e);
          setFetchYelpReviewsInProgress(false);

          // Throw error only if listing has yelp credentials
          // and issue happens while we are fetching the reviews
          if (hasYelp) {
            setYelpReviewsError(e?.response?.data?.message ?? 'Failed to fetch reviews from yelp');
          }
        }
      })();
    }
  }, []);

  // Combine the reviews response
  const combinedReviews = yelpReviews?.length > 0 ? [...reviews, ...yelpReviews] : reviews;

  if (combinedReviews?.length === 0 || combinedReviews?.length < 0) {
    return null;
  }

  const reviewsError = (
    <h2 className={css.errorText}>
      <FormattedMessage id="ListingPage.reviewsError" />
    </h2>
  );

  return (
    <div className={css.sectionReviews}>
      <h2 className={css.reviewsHeading}>
        <FormattedMessage
          id="ListingPage.reviewsHeading"
          values={{
            count: combinedReviews?.filter(r => !BLACKLISTED_REVIEW_IDS.includes(r.id.uuid))
              ?.length,
          }}
        />
      </h2>
      {fetchReviewsError ? reviewsError : null}
      <Reviews
        reviews={combinedReviews}
        oldReviews={oldReviews}
        fetchYelpReviewsInProgress={fetchYelpReviewsInProgress}
        customerReviews={customerReviews}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    </div>
  );
};

export default SectionReviews;
