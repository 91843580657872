import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, IconArrowHead } from '../../components';

import css from './ListingPage.module.css';

const DEFAULT_PAGE = 'SearchPage';
const ICON_POSITION = 'left';

const ReturnLink = props => {
  const { location } = props;
  const { prevPath } = location?.state || {};

  // Destruct the prevPath
  const { page, search, params } = prevPath || {};

  // Either receive the page from location state
  // or use the SearchPage as the default one
  const pageRedirect = page ? page : DEFAULT_PAGE;
  return (
    <div className={css.returnLink}>
      <NamedLink name={pageRedirect} to={{ search }} params={{ ...params }}>
        <IconArrowHead className={css.returnIcon} direction={ICON_POSITION} />
        <FormattedMessage id="ListingPage.return" />
      </NamedLink>
    </div>
  );
};

export default ReturnLink;
