import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { InlineTextButton } from '../../components';
import truncate from 'lodash/truncate';

import css from './ListingPage.module.css';

const LOCATION_COLLAPSED_LENGTH = 50;

const truncated = s => {
  return truncate(s, {
    length: LOCATION_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const SectionHeading = props => {
  const {
    richTitle,
    showContactUser,
    onContactUser,
    formattedPrice,
    priceTitle,
    location,
    customAddress,
  } = props;

  // Split the location
  const splitLocation = location?.split(',');

  // Get only last two comma values
  let stateAndCountry = splitLocation?.slice(-2).join(',');

  stateAndCountry = stateAndCountry?.replace(/[0-9]/g, ''); // Remove numbers
  stateAndCountry = stateAndCountry?.replace(/ \,/g, ','); // Remove space before comma

  // Apply truncated function that will
  // cut the words if they are too long
  const truncatedLocation = truncated(stateAndCountry?.trim());
  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.author}>
          {formattedPrice ? (
            <>
              <span className={css.headingPrice} title={priceTitle}>
                {formattedPrice}
              </span>
              <span className={css.separator}>•</span>
            </>
          ) : null}
          {customAddress ? (
            <span className={css.headingLocation}>{customAddress}</span>
          ) : location ? (
            <span className={css.headingLocation}>{truncatedLocation}</span>
          ) : null}
          {showContactUser ? (
            <>
              <span className={css.separator}>•</span>
              <span className={css.contactWrapper}>
                <InlineTextButton
                  rootClassName={css.contactLink}
                  onClick={onContactUser}
                  enforcePagePreloadFor="SignupPage"
                >
                  <FormattedMessage id="ListingPage.contactUser" />
                </InlineTextButton>
              </span>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
