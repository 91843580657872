import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Accordion } from '../../components';

import css from './ListingPage.module.css';

const SectionMiniSessionFeatures = props => {
  const { publicData, turnaroundTimeConfig } = props;
  const { digitalFiles, turnaroundTime } = publicData || {};

  if (!digitalFiles || !turnaroundTime) {
    return null;
  }

  const matchTimeFromConfig = turnaroundTimeConfig?.find(time => time.key === turnaroundTime)
    ?.label;
  const accordions = [
    { key: 'digital-files', label: 'Digital files', content: digitalFiles },
    { key: 'turnaround-time', label: 'Turnaround time', content: matchTimeFromConfig },
  ];

  return (
    <div className={css.sectionMiniSessionFeatures}>
      <h2 className={css.miniSessionFeaturesTitle}>
        <FormattedMessage id="ListingPage.miniSessionFeaturesTitle" />
      </h2>
      <Accordion className={css.accordion} accordions={accordions} />
    </div>
  );
};

export default SectionMiniSessionFeatures;
